import useRestClient from '../hooks/rest.hook';
import useQuery from '../hooks/useQuery.hook';
import { OriginType } from '../types/common/index.type';
import { PollingRes } from '../types/common/response.type';
import { BankRes, IncomeVerify, SubmitIncomeRes } from '../types/income-verify.type';
import { convertObjectToQueryParams } from '../utils';

export default function useIncomeVerifySvc() {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { id, bsId } = queryParams;

    async function getBanksList(): Promise<BankRes> {
        return restClient.get(`/pub/bs/banks`);
    }

    async function submitIncomeVerify(incomeVerifyDetails: IncomeVerify, isIframe: boolean): Promise<SubmitIncomeRes> {
        const params = convertObjectToQueryParams({ ...queryParams, poll: true, ...(isIframe ? { origin : OriginType.IFRAME} : {}) });
        const url = `${window.location.origin}/income_verify?${params}`;
        const postData = { ...incomeVerifyDetails, redirectUrl: url };
        return restClient.post(`/bs/init?id=${id}`, postData);
    }

    async function status(): Promise<PollingRes> {
        const params = convertObjectToQueryParams({ id, bsId });
        return restClient.get(`/bs/status?${params}`);
    }

    return {
        getBanksList,
        submitIncomeVerify,
        status,
    };
}
