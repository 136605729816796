import { useEffect, useContext, useState } from 'react';
import Loader from '../components/Loader';
import { AppContext } from '../context/app';
import useCheckoutSvc from '../services/checkout.svc';
import useEligibilitySvc from '../services/eligibility.svc';
import useQuery from '../hooks/useQuery.hook';

export default function Home() {
    const [loading, setLoading] = useState(true);
    const { handleError, getUserPlan, handleEligibility, moveNext, isEligibilityFlow, state } = useContext(AppContext);
    const { checkout } = useCheckoutSvc();
    const { checkEligibility } = useEligibilitySvc();
    const {
        queryParams: { id, planId },
        addParams,
    } = useQuery();
    const {
        planConfig: { plans },
    } = state;

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (isEligibilityFlow) {
                    const eligibility = await checkEligibility();
                    handleEligibility(eligibility);
                } else {
                    if (id) {
                        await moveNext();
                        if (planId) {
                            getUserPlan();
                        }
                    } else {
                        if (plans.length) {
                            const checkoutState = await checkout();
                            addParams({ cid: checkoutState.id });
                        }
                    }
                }
            } catch (error) {
                handleError(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [id, plans]);

    if (loading) {
        return <Loader />;
    }
    return <></>;
}
