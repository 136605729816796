import { createContext, useContext, useEffect, useState } from 'react';
import { AppContext } from '../app';
import useIncomeVerifySvc from '../../services/income-verify.svc';
import { Bank, IncomeVerify } from '../../types/income-verify.type';
import { usePolling } from '../../hooks/poll.hook';
import useCheckoutSvc from '../../services/checkout.svc';
import useQuery from '../../hooks/useQuery.hook';
import { PollingStatus } from '../../types/common/response.type';
import { ViewType } from '../../types/common/index.type';
import useTabHelper from '../../hooks/tab-helper.hook';

interface IncomeVerifyContextType {
    loading: boolean;
    bankList: Array<Bank>;
    submitIncome: (form: IncomeVerify) => void;
    isPolling: boolean;
    pollingStatus: PollingStatus;
    stopPolling: () => void;
    handleTabFocus: () => void;
}

export const IncomeVerifyContext = createContext<IncomeVerifyContextType>({} as IncomeVerifyContextType);

export const IncomeVerifyProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState(false);
    const [bankList, setBankList] = useState<Array<Bank>>([]);
    const { handleError, handleCheckout, currentView } = useContext(AppContext);
    const { getBanksList, submitIncomeVerify, status } = useIncomeVerifySvc();
    const {
        queryParams: { poll },
    } = useQuery();
    const { getCheckoutState } = useCheckoutSvc();
    const { openUrlInNewTab, clearTabStatus, handleTabFocus } = useTabHelper();
    const REACT_APP_INCOME_VERIFY_POLLING_INTERVAL = Number(process.env.REACT_APP_INCOME_VERIFY_POLLING_INTERVAL as string || '5000');
    const REACT_APP_IFRAME_INCOME_VERIFY_POLLING_INTERVAL = Number(process.env.REACT_APP_IFRAME_INCOME_VERIFY_POLLING_INTERVAL as string || '5000');
    const REACT_APP_INCOME_VERIFY_POLLING_RETRIES = Number(process.env.REACT_APP_INCOME_VERIFY_POLLING_RETRIES as string || '15');
    const REACT_APP_IFRAME_INCOME_VERIFY_POLLING_RETRIES = Number(process.env.REACT_APP_IFRAME_INCOME_VERIFY_POLLING_RETRIES as string || '15');

    const { beginPolling, isPolling, pollingStatus, stopPolling, onPollingFailure } = usePolling({
        callback: async () => await status(),
        onSuccess: async () => {
            const checkoutStateDetails = await getCheckoutState();
            // Added await so that loader won't stop instantly
            await handleCheckout(checkoutStateDetails);
        },
        onFailure: async () => {
            const checkoutStateDetails = await getCheckoutState();
            // Added await so that loader won't stop instantly
            await handleCheckout(checkoutStateDetails);
            clearTabStatus();
        },
        interval: currentView === ViewType.IFRAME ? REACT_APP_IFRAME_INCOME_VERIFY_POLLING_INTERVAL : REACT_APP_INCOME_VERIFY_POLLING_INTERVAL,
        maxRetries: currentView === ViewType.IFRAME ? REACT_APP_IFRAME_INCOME_VERIFY_POLLING_RETRIES : REACT_APP_INCOME_VERIFY_POLLING_RETRIES,
    });

    const fetchBanksList = async () => {
        try {
            setLoading(true);
            const bankDetailsRes = await getBanksList();
            const { bankList } = bankDetailsRes ?? {};
            setBankList(bankList);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const onTabOpen = () => {
        beginPolling();
    };
    const onTabClose = () => {
        onPollingFailure();
    };
    const submitIncome = async (form: IncomeVerify) => {
        try {
            setLoading(true);
            const incomeVerifyRes = await submitIncomeVerify({ ...form }, currentView === ViewType.IFRAME);
            const { url } = incomeVerifyRes ?? {};
            if (currentView === ViewType.IFRAME) {
                setLoading(false);
                openUrlInNewTab(url, onTabOpen, onTabClose, 'get');
            } else {
                window.location.replace(url);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (poll) {
            beginPolling();
        } else {
            fetchBanksList();
        }
    }, [poll]);

    const values = { loading, bankList, submitIncome, isPolling, pollingStatus, stopPolling, handleTabFocus };
    return <IncomeVerifyContext.Provider value={values}>{children}</IncomeVerifyContext.Provider>;
};
