import { Route, Routes } from 'react-router-dom';
import RouteType from '../types/route.type';
import { routes } from './all-routes';

export default function Index() {

    return (
        <Routes>
            {routes.map((route: RouteType, idx) => (
                <Route path={route.path} element={route.component} key={idx} />
            ))}
        </Routes>
    );
}
