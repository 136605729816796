import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig, isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery.hook';
import { useCallback } from 'react';
import regexMap from '../regex';
import { removeLSKey, getLSValue } from '../utils/localstorage.helper';

export default function useRestClient() {
    const { queryParamsString } = useQuery();
    const navigate = useNavigate();

    const restClientCallback = useCallback<() => AxiosInstance>(() => {
        const { http_5xx } = regexMap;

        const restClient = axios.create({
            baseURL: process.env.NODE_ENV === 'development' ? undefined : process.env.REACT_APP_API_BASE_URL,
        });

        restClient.interceptors.request.use((request: InternalAxiosRequestConfig) => {
            const token = getLSValue('tk');
            if (token) {
                request.headers['Authorization'] = 'Bearer ' + token;
            }
            return request;
        });

        restClient.interceptors.response.use(
            (res: AxiosResponse) => {
                return res.data;
            },
            (error) => {
                if (isAxiosError(error)) {
                    const {
                        status,
                        data: { code = '', message = '' },
                    } = error.response || {};

                    const errorMsg: string = message || code;
                    const stausCode = String(status);

                    if (stausCode === '403') {
                        removeLSKey('tk');
                        navigate(`/login?${queryParamsString}&redirectUrl=${window.location.pathname}`, {
                            replace: true,
                        });
                    } else if (http_5xx.test(stausCode)) {
                        navigate(`/error?${queryParamsString}`, {
                            state: {
                                message: errorMsg,
                            },
                        });
                    }
                }
                throw error;
            }
        );
        return restClient;
    }, []);
    return restClientCallback();
}
