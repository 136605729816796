import useRestClient from '../hooks/rest.hook';
import useQuery from '../hooks/useQuery.hook';
import { AutoDebitDataRes, AutoDebitReq, Banks, EmandateResType, UpiSiRes } from '../types/auto-debit.type';
import { OriginType } from '../types/common/index.type';
import { PollingRes } from '../types/common/response.type';
import { convertObjectToQueryParams } from '../utils';

export default function useAutoDebitSvc() {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { id, fetchType } = queryParams;

    async function getAutoDebit(): Promise<AutoDebitDataRes> {
        return restClient.get(`/payments/auto-debit/upi-intents?id=${id}`);
    }

    async function getBankList(): Promise<Banks[]> {
        return restClient.get(`/payments/auto-debit/emandate/banks?id=${id}`);
    }

    async function getIfscCodeList(ifscCode: string, bankId: string): Promise<string[]> {
        return restClient.get(`/payments/auto-debit/ifscs?id=${id}&bankId=${bankId}&ifsc=${ifscCode}`);
    }

    async function updateMitcStatus(status: boolean): Promise<void> {
        return restClient.post(`/payments/mitc?id=${id}`, { status });
    }

    async function submitAutoDebit(data: AutoDebitReq, method: string = '', isIframe: boolean = false): Promise<EmandateResType | UpiSiRes> {
        const params = convertObjectToQueryParams({
            ...queryParams,
            fetchType,
            poll: true,
            ...(method ? { method } : {}),
            ...(isIframe ? { origin : OriginType.IFRAME} : {}),
        });
        const url = `${window.location.origin}/auto-debit?${params}`;
        const requestData = {
            ...data,
            successUrl: url,
            failureUrl: url,
        };
        return restClient.post(`/payments/auto-debit?id=${id}`, requestData);
    }

    async function pollStatus(method: string | null): Promise<PollingRes> {
        return restClient.get(`/payments/auto-debit/status?id=${id}&autoDebitMethod=${method}`);
    }

    async function getMitcUrl(): Promise<{ mitcUrl: string }> {
        return restClient.get(`/payments/mitc-url?id=${id}`);
    }

    return {
        getAutoDebit,
        getBankList,
        getIfscCodeList,
        updateMitcStatus,
        submitAutoDebit,
        pollStatus,
        getMitcUrl,
    };
}
